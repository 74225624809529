<template>
  <div>
    <p class="mb-0">
      Which of the following pairs of compounds would make the
      <span class="text-bold text-danger">best</span>
      buffer system?
    </p>

    <v-form @submit.prevent="submitResponse">
      <v-radio-group v-model="inputs.input1" :disabled="!allowEditing" class="mt-1 pl-6 mb-n6">
        <div v-for="option in options1" :key="option.value">
          <v-radio class="d-inline-block" :value="option.value" />
          <stemble-latex :content="option.expression" />
        </div>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question170h',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
      },
      options1: [
        {expression: '$\\ce{NaOH and HCl}$', value: 'SASB'},
        {expression: '$\\ce{NaOH and NH3}$', value: 'SBWB'},
        {expression: '$\\ce{CH3COOH and C6H5COOH}$', value: 'WAWA'},
        {expression: '$\\ce{CH3COOH and NH3}$', value: 'WAWB'},
        {expression: '$\\ce{HCl and CH3COOH}$', value: 'SAWA'},
        {expression: '$\\ce{C6H5COOH and C6H5COONa}$', value: 'CABP'},
      ],
    };
  },
};
</script>
